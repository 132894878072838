import React, {useEffect, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import H from "@here/maps-api-for-javascript";
import './leaflet.css';
import './styles.css';

import {
    afterDrag,
    calculateRoute, drawLineOnMap,
    geocode,
    hereMarker, removeObjectFromMap,
    setDraggable
} from "./hereFunctions";

import {hereMapApiKey} from "../../../deployment";
import {AppStateType} from "../../../redux/store";
import {
    ROAD_CKAD,
    ROAD_MKAD,
    ROAD_SAD,
    ROAD_TTK, setPoints, setRouteLoading
} from "../../../redux/mapReducer";
import data from "./data_regions";
import {addRectangleToMap} from "./resizeableRectangle";
import {MapPoint} from "../../../redux/mapTypes";

interface Props {
}

export var map : H.Map;
var currentPoints : MapPoint[] = [];

export const AVOID_MKAD_BBOX = 'mkadbbox';
export const AVOID_CKAD_BBOX = 'ckadbbox';
export const AVOID_TTK_BBOX = 'ttkbbox';
export const AVOID_SAD_BBOX = 'sadbbox';

let HereMapContainer : React.FC<Props> = ({})  => {
    const mapCont = useRef<HTMLHeadingElement>(null);
    const isFirstLoad = useRef({isTrue:true}).current;

    const dispatch = useDispatch();

    let mapReducer = useSelector((state: AppStateType) => state.mapReducer);

    // @ts-ignore
    // const points : MapPoint[] = useAppSelector(state => state.mapReducer.points);
    const {points, road_selected, date_time, routing_mode, avoid_features, params_truck, use_jams,car_type} = useSelector<AppStateType, { points: MapPoint[]}> ((state: AppStateType) => {
        return { points: state.mapReducer.points,
                 road_selected:  state.mapReducer.road_selected,
                 date_time:  state.mapReducer.date_time_iso,
                 routing_mode:  state.mapReducer.routing_mode,
                 avoid_features:  state.mapReducer.avoid_features,
                 params_truck:  state.mapReducer.params_truck,
                 use_jams:  state.mapReducer.use_jams,
                 car_type:  state.mapReducer.car_type,
               }
    });
    const prevPoints  = useRef({ points }).current;
    currentPoints = [...points];
    // let waypoints = mapReducer.waypoints;
    // let mkadRoutes = mapReducer.mkadRoutes;


    useEffect(() => {

        const platform = new H.service.Platform({
            'apikey': hereMapApiKey
        });

        if (!map || !mapCont || !mapCont.current || mapCont.current.childElementCount === 0) {
            const defaultLayers = platform.createDefaultLayers({
                lg:'ru_RU'
            });

            //center: { lat: 55.7511, lng: 37.6218 },
            map = new H.Map(
                mapCont.current,
                defaultLayers.vector.normal.map,
                {
                    // This map is centered over Europe
                    center: { lat: 55.7511, lng: 37.6218 },
                    zoom: 12,
                    pixelRatio: window.devicePixelRatio || 1
                }
            );

            var events = new H.mapevents.MapEvents(map);
            // // eslint-disable-next-line
            var behavior = new H.mapevents.Behavior(events);

            var ui   = H.ui.UI.createDefault(map, defaultLayers, 'ru-RU');

            map.addEventListener('tap', (evt:any)=> {
                // if (evt.target instanceof H.map.DomMarker
                //     || evt.target instanceof H.map.Marker) {
                //     var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
                //         // read custom data
                //         content: "Удалить точку ?"
                //     });
                //     // show info bubble
                //     if (ui) ui.addBubble(bubble);
                //     return;
                // }
                var coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY) as H.geo.Point ;

                let marker = hereMarker('', [coord.lat, coord.lng], currentPoints.length);
                map.addObject(marker);

                geocode([coord.lat, coord.lng], platform, dispatch, currentPoints.length);
            });

            setDraggable(map, behavior, (map) => {
                afterDrag(map,  currentPoints, platform, dispatch);
            });

            //setTimeout(()=> addDraggableMarker(map,behavior), 1000)

            // add a resize listener to make sure that the map occupies the whole container
            window.addEventListener('resize', () =>
                map.getViewPort().resize()
            );

            // createResizableRect(map, behavior, [ 55.7511, 37.6218, 55.7911, 37.6918]);
        }

        if (isFirstLoad.isTrue ) {
            let url = new URL(window.location.href);
            let coords = url.searchParams.get('coords');
            if (coords) {
                let arrayPoints : MapPoint[] = [];
                coords.split(';').forEach((c)=>{
                    let pointCoordinates = c.split(',').map(c => +c);
                    let point : MapPoint = {lng:pointCoordinates[0], lat:pointCoordinates[1]};
                    let marker = hereMarker('', [point.lat,point.lng], arrayPoints.length);
                    map.addObject(marker);
                    arrayPoints.push(point);

                });
                dispatch(setPoints(arrayPoints));


            }

        }
        const localWaypoints = [... points];
        const _waypoints = localWaypoints.map((waypoint) => waypoint.lat.toFixed(4) + ',' + waypoint.lng.toFixed(4));


        let avoid = '';

        if (road_selected.mkad){
            drawLineOnMap(data.OUT_MKAD as [number,number][], map, ROAD_MKAD);
            // let countPoints = 300;
            // for (let i = countPoints; i < MKAD.length; i+=countPoints) {
            //     if (avoid == '') avoid = "bbox:" + MKAD[i-countPoints][0] + ',' + MKAD[i-countPoints][1] + ',' + + MKAD[i][0] + ',' + MKAD[i][1] ;
            //     else avoid = avoid + "|" + "bbox:" + MKAD[i-countPoints][0] + ',' + MKAD[i-countPoints][1] + ',' + + MKAD[i][0] + ',' + MKAD[i][1] ;
            //     //if (avoid == '') avoid = "bbox:" + MKAD[i-100].join(',') + ',' + MKAD[i].join(',');
            //     //else avoid = avoid + "|" + "bbox:" + MKAD[i-100][1] + ',' + MKAD[i-100][0] + ',' + + MKAD[i][1] + ',' + MKAD[i][0] ;
            // }
            //
            // avoid = avoid + "|" + "bbox:" + MKAD[MKAD.length-countPoints][0] + ',' + MKAD[MKAD.length-countPoints][1] + ',' + + MKAD[MKAD.length-1][0] + ',' + MKAD[MKAD.length-1][1] ;
            //avoid = "bbox:37.85468862474833,55.565968667555666,37.35366246591246,55.92225411060523";
            data.MKAD_BBOX.forEach( bbox=> {addRectangleToMap(map, bbox, AVOID_MKAD_BBOX);
                if (avoid === '')  avoid += bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
                else avoid += '!' + bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
                //else  avoid = avoid + "|bbox:" + [bbox[1],bbox[0],bbox[3],bbox[2]].join(',');
            } );
        } else {
            removeObjectFromMap(map, ROAD_MKAD);
            removeObjectFromMap(map, AVOID_MKAD_BBOX);
        }

        if (road_selected.ttk){
            drawLineOnMap(data.TTK as [number,number][], map, ROAD_TTK);
            data.TTK_BBOX.forEach( bbox=> {addRectangleToMap(map, bbox, AVOID_TTK_BBOX);
                if (avoid === '')  avoid += bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
                else avoid += '!' + bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
            } );
        } else {
            removeObjectFromMap(map, ROAD_TTK);
            removeObjectFromMap(map, AVOID_TTK_BBOX);
        }

        if (road_selected.sad){
            drawLineOnMap(data.SAD as [number,number][], map, ROAD_SAD);
            data.SAD_BBOX.forEach( bbox=> {addRectangleToMap(map, bbox, AVOID_SAD_BBOX);
                if (avoid === '')  avoid += bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
                else avoid += '!' + bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
            } );
        } else {
            removeObjectFromMap(map, ROAD_SAD);
            removeObjectFromMap(map, AVOID_SAD_BBOX);
        }
        if (road_selected.ckad){
             // drawLineOnMap(CKAD as [number,number][], map, ROAD_CKAD);
            drawLineOnMap(data.CKAD2 as [number,number][], map, ROAD_CKAD);

            data.CKAD_BBOX.forEach( bbox=> {addRectangleToMap(map, bbox, AVOID_CKAD_BBOX);
                if (avoid === '')  avoid += bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
                else avoid += '!' + bbox[0] + ',' + bbox[1] + ';' + bbox[2] + ',' +bbox[3] ;
            } );
        } else {
            removeObjectFromMap(map, ROAD_CKAD);
            removeObjectFromMap(map, AVOID_CKAD_BBOX);
        }


        (  async  function(){
                   await dispatch(setRouteLoading(true));

                   await dispatch( calculateRoute(platform, _waypoints, map, {
                        avoidPoints: avoid,
                        routingMode: routing_mode,
                        departureTime: date_time,
                        avoidFeatures: avoid_features.join(','),
                        paramsTruck: params_truck,
                        useJams: use_jams,
                        carType: car_type
                    }));
                    dispatch(setRouteLoading(false));


            }
        )()

        // if (points.length>1) drawWayOnMap(points, map)

        // if (map ) {
        //     let objects: H.map.Object[] = map.getObjects().filter( obj => !(obj instanceof H.map.DomMarker || obj instanceof H.map.Marker)) as H.map.Object[];
        //     map.removeObjects(objects);
        // }


        // mapR.points.forEach((point, i) => {
        //     let label = i === 0 ? 'Откуда' : 'Куда';
        //     let marker = hereMarker(label, [point.lat, point.lng], i + 1, mapR.points[i]);
        //     map.addObject(marker);
        // });

        return () => {
            prevPoints.points = points;
            isFirstLoad.isTrue = false
        }
        }, [dispatch, points, road_selected, routing_mode, date_time, avoid_features, params_truck, use_jams, car_type]);
    


    return (
        <div ref={mapCont} id={"here-map"} style={{ height: "100%" }} />
    );
};

export default HereMapContainer
