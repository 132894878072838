import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {connect, Provider} from 'react-redux';
import store, {AppStateType} from './redux/store';
import MapApp from './components/MapApp/MapApp';

function App() {
    console.log('APP');
  return (
      <Switch>
        <Route exact path='/' component={MapApp}/>
      </Switch>
  );

}


const mapStateToProps = (state: AppStateType) => ({});

let AppContainer = connect(mapStateToProps, {})(App);

const CheckoutApp = () => {
  return <Provider store={store}>
    <BrowserRouter basename="/">
      <AppContainer/>
    </BrowserRouter>
  </Provider>;
};

export default CheckoutApp;
