import  turf  from "turf"
import data from "./data_regions";
import {setCalculatedWayPoints} from "../../../redux/mapReducer";
import {drawWayToMKAD, getWayFromPointToPoint, hereMarker, removeObjectFromMap} from "./hereFunctions";
import H from "@here/maps-api-for-javascript";
import {map} from "./HereMapContainer";

const lineIntersect  = require('@turf/line-intersect').default;
const pointToLineDistance  = require('@turf/point-to-line-distance').default;

export type CoordinatePoint = [number,number];

const getIntersection = (coordinate1:any[], coordinate2:CoordinatePoint[]) =>{
    let d1: any = turf.lineString(coordinate1);
    let d2: any = turf.lineString(coordinate2);
    var intersection = turf.intersect(d1, d2);

    if (!intersection || !intersection.geometry ||  !intersection.geometry?.coordinates?.length)
            return null;
    return intersection;
};

const lineSplitCorrected = (coordinates:CoordinatePoint[], splitter: CoordinatePoint[] ) => {
    let line = turf.lineString(coordinates);
    const splitOwn = turf.featureCollection([line]);

    const intersections = lineIntersect(line, turf.polygon([splitter])).features;
    for (let i = 0; i < intersections.length; i++) {
        let point = intersections[i];

        // check if intersection is on line
        for (let j = 0; j < splitOwn.features.length; j++) {
            const lineString = splitOwn.features[j];

            if (!point) {
                break;
            }


            if ( pointToLineDistance(point, lineString) > 0.00001) {
                // Too far, this point is not on this segment
                continue;
            }

            // split line into two
            splitOwn.features.splice(j, 1);
            const pointStart = turf.point(lineString.geometry.coordinates[0]);
            const pointStop = turf.point(lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1]);
            splitOwn.features.push(turf.lineSlice(pointStart, point, line));
            splitOwn.features.push(turf.lineSlice(point, pointStop, line));

            point = null;
        }
    }

    // remove short splits
    for (let i = splitOwn.features.length - 1; i >= 0; i--) {
        if ( turf.lineDistance(splitOwn.features[i]) < 0.00001) splitOwn.features.splice(i, 1);
    }

    return splitOwn;
};


/**
 * @brief Расчет дистанции между точками А и Б на маршруте
 * @param pointA - точка А
 * @param pointB - точка Б
 * @param isKM - возвращать дистанцию в километрах
 * @coordinatesWay - координаты, точки маршрута
 */
const getDistance = (pointA: number[], pointB: number[] , isKM = true) => {
    if (!pointA || pointA.length < 2 || !pointB || pointB.length < 2)
        return 0;
    const from = turf.point( pointA  );
    const to = turf.point( pointB );
    let distance = turf.distance(from, to,   "kilometers" );
    return distance;
};

const  getDistanceWay = (path: CoordinatePoint[]) => {
    if (path.length<2) return -1;
    const line = turf.lineString( path );
    let distance = turf.lineDistance( line,  'metres' );
    return  distance;
};

const  getDistanceWayFromTo = (pointA: CoordinatePoint, pointB: CoordinatePoint, coordinatesWay: CoordinatePoint[]  ) => {
    const line = turf.lineString( coordinatesWay );
    const start = turf.point( pointA);
    const stop  = turf.point( pointB);
    const slice = turf.lineSlice(start,stop, line);
    let distance = turf.lineDistance( slice,  'metres' );
    return  {distance:distance , slice:slice.geometry.coordinates};
};

const isInRegion = (point:CoordinatePoint) => {

    const start = turf.point( point);
    if (turf.inside(start, turf.polygon( [correctPolygon(data.BK) ]), )) {
        return "bk";
    }

    if (turf.inside(start, turf.polygon( [correctPolygon(data.SAD) ])) ) {
        return "sk";
    }

    if (turf.inside(start, turf.polygon( [correctPolygon(data.TTK) ])) ) {
        return "ttk";
    }

    if (turf.inside(start, turf.polygon( [correctPolygon(data.MKAD) ])) || isInMkad(point, data.OUT_MKAD) ) {
        return "mkad";
    }

    if (turf.inside(start, turf.polygon( [correctPolygon(data.CKAD2) ])) ) {
        return "ckad";
    }

    return "";
};

const correctPolygon = (poly:CoordinatePoint[]) => {
    if (poly[0][0] != poly[poly.length-1][0] || poly[0][1] != poly[poly.length-1][1])
        poly.push(poly[0]);
    return poly
};

const isInMkad = (point:CoordinatePoint, poly:CoordinatePoint[] | null = null ) => {
    if (!poly) {
        poly = data.MKAD as CoordinatePoint[];
    }

    const p = turf.point( point);
    const pl = turf.polygon(  [correctPolygon(poly)] );
    console.log('isInMkad', p,pl);
    return turf.inside(p,pl);
};

const splitByMkad = (line:CoordinatePoint[], poly:CoordinatePoint[])=> {
    if (!poly) {
        poly = data.MKAD as CoordinatePoint[];
    }
    return lineSplitCorrected(line, poly);
};


const simplifyResult = (input:any[]) => {
    // console.log(input);

    if (input.length <= 3) {
        return input;
    }

    if (input[0].inside != input[input.length - 1].inside) {
        const result = [];
        if (input[0].inside) {
            const distance = input.slice(0, input.length - 1).reduce((acc, c) => acc + c.distance, 0);
            result.push({ inside: input[0].inside, distance });
            result.push(input[input.length - 1]);
        } else {
            result.push(input[0]);
            const distance = input.slice(1).reduce((acc, c) => acc + c.distance, 0);
            result.push({ distance, inside: input[1].inside });
        }
        return result;
    }

    if (input[0].inside == input[input.length - 1].inside) {
        const result = input.slice(0, 1);
        const distance = input.slice(1, input.length - 1).reduce((acc, c) => acc + c.distance, 0);
        result.push({ inside: input[1].inside, distance });
        result.push(input[input.length - 1]);
        return result;
    }

    return input;
}

const getFirstCoordinateInteractionWhitMKAD = (wayCoordinate: any[]) : CoordinatePoint|null => {
    let interaction = getIntersection(wayCoordinate as [], data.OUT_MKAD);
    let coordinates = interaction && interaction.geometry.type === "Point" ? interaction.geometry.coordinates as number[] :
        interaction ? interaction.geometry.coordinates[0] as number[] : null;
    return coordinates ? coordinates as CoordinatePoint : null;
};

const findNearestPointMKAD = async (point: CoordinatePoint, routeRequestParams:Object, platform: H.service.Platform, map:H.Map, isStart = false)  => {
    let wayCoordinate = await getWayFromPointToPoint(isStart? data.CENTER :  point, isStart? point: data.CENTER , routeRequestParams, platform, map, false);
    let pointMKAD = getFirstCoordinateInteractionWhitMKAD(wayCoordinate as []);
    let value:{distance: number, point: CoordinatePoint , way: CoordinatePoint[]}|null;
    if (pointMKAD != null) {
        let line = turf.lineSlice(turf.point(point), turf.point(pointMKAD), turf.lineString(wayCoordinate as number[][]));
        let distance = turf.lineDistance(line, 'meters');
        value = {
            point: pointMKAD,
            distance: distance,
            way: line.geometry.coordinates as CoordinatePoint[]
        }
    } else  value = null;
    return new Promise<{distance: number, point: CoordinatePoint , way: CoordinatePoint[]}|null>(        resolve =>  resolve(value));
};

export let calculateGeoPath = async (path: CoordinatePoint[], pointsSource: CoordinatePoint[], wayPointData: { mappedPosition: { latitude: number, longitude: number }}[],
                                 dispatch:any, routeRequestParams:Object, platform: H.service.Platform, map:H.Map) => {
    if (!path.length) return null;
    const getLetter = getChar();
    let pathLength = getDistanceWay(path);
    let wayPoints: CoordinatePoint[] = wayPointData.map(x => [x.mappedPosition.longitude, x.mappedPosition.latitude]);
    // let pathLengthStraight = getDistance(points[0], points[1]);
    // pathLengthStraight = pathLengthStraight;
    let result: wayPointInfo[] = [];
    let pathLengthStraight = getDistance(wayPoints[0], wayPoints[1]);
    removeObjectFromMap(map, 'marker_mkad');
    if (! isInMkad(wayPoints[0], data.OUT_MKAD) ){
        // let interaction = getIntersection(path, data.OUT_MKAD);
        // let coordinates = interaction && interaction.geometry.type === "Point" ? interaction.geometry.coordinates as number[] :
        //                   interaction ? interaction.geometry.coordinates[0] as number[] : [];
        // if (coordinates.length) {
        //     let wayCoordinate = await getWayFromPointToPoint(coordinates, wayPoints[0] as number[], routeRequestParams, platform, map);
        //     let distance = getDistanceWay(wayCoordinate as  CoordinatePoint[]);
        let pointMKAD =  await findNearestPointMKAD(wayPoints[0], routeRequestParams,platform,map, true);
        if (pointMKAD) {
            drawWayToMKAD(pointMKAD.way, map);
            let marker = hereMarker(MKAD_START, pointMKAD.point.reverse() , 0);
            marker.setData({name:'marker_mkad'});
            map.addObject(marker);
            result.push({
                from: MKAD_START,
                to:  getLetter('current') ,
                distance: pointMKAD.distance,
                fromReg: '',
                toReg: isInRegion(wayPoints[0]),
                insideMKAD: false
            });
        }
    }

    for (let i = 1; i < wayPoints.length; i++) {
        let startWayPoint: CoordinatePoint = wayPoints[i - 1];
        let endWayPoint: CoordinatePoint = wayPoints[i];
        let {distance, slice } = getDistanceWayFromTo(startWayPoint, endWayPoint, path);
        let intersectionMKAD : GeoJSON.Feature | null = getIntersection(data.OUT_MKAD, slice as CoordinatePoint[]);
        if (!intersectionMKAD) {
            result.push( {
                distance: distance,
                insideMKAD: isInMkad(startWayPoint, data.OUT_MKAD) && isInMkad(endWayPoint, data.OUT_MKAD),
                fromReg: isInRegion(startWayPoint),
                toReg: isInRegion(endWayPoint),
                from:   getLetter('current') ,
                to:   getLetter('next') ,
            })
        }  else {
            const parts =  splitByMkad(slice as CoordinatePoint[], data.OUT_MKAD);
            let pp : {inside:boolean,distance:number,geometry:CoordinatePoint[]}[] = simplifyResult(
                parts.features.map(item => {
                    return {
                        inside: isInMkad(item.geometry.coordinates[1], data.OUT_MKAD),
                        distance: turf.lineDistance( item.geometry,  'metres' ),
                        geometry: JSON.stringify(item.geometry.coordinates)
                    };
                })
            );

            result.push({
                from:  getLetter('current') ,
                to:  "МКАД" ,
                distance: pp[0].distance,
                fromReg: isInRegion(startWayPoint),
                toReg: '',
                insideMKAD: pp[0].inside
            });
            for (let j = 1; j < pp.length - 1; j++) {
                result.push({
                    from:  "МКАД" ,
                    to:  "МКАД" ,
                    distance: pp[j].distance,
                    fromReg: '',
                    toReg: '',
                    insideMKAD: pp[j].inside
                });
            }
            result.push({
                from:  "МКАД" ,
                to:   getLetter('next'),
                distance: pp[pp.length - 1].distance,
                fromReg: '',
                toReg: isInRegion(endWayPoint),
                insideMKAD: pp[pp.length - 1].inside
            });
        }
    }

    if (! isInMkad(wayPoints[wayPoints.length-1], data.OUT_MKAD)){
        // let interaction = getIntersection(path, data.OUT_MKAD);
        // let coordinates = interaction && interaction.geometry.type === "Point" ? interaction.geometry.coordinates as number[] :
        //     interaction ? interaction.geometry.coordinates[interaction.geometry.coordinates.length-1] as number[] : [];
        // let wayCoordinate = await getWayFromPointToPoint(coordinates, wayPoints[wayPoints.length-1] as number[], routeRequestParams, platform, map);
        // if (coordinates.length) {
        let pointMKAD =  await findNearestPointMKAD(wayPoints[wayPoints.length-1], routeRequestParams,platform,map);
        if (pointMKAD) {
            drawWayToMKAD(pointMKAD.way, map);
            let marker = hereMarker(MKAD_FINISH, pointMKAD.point.reverse() , 0);
            marker.setData({name:'marker_mkad'});
            map.addObject(marker);

            result.push({
                from: getLetter('current'),
                to: MKAD_FINISH,
                distance: pointMKAD.distance,
                fromReg: isInRegion(wayPoints[wayPoints.length - 1]),
                toReg: '',
                insideMKAD: false
            });
        }
    }

    dispatch(setCalculatedWayPoints(result));

};
export const MKAD_FINISH = "МКАД финиш";
export const MKAD_START = "МКАД старт";

const getChar = () => {
    let counter = 0;
    return function (type: 'next'|'prev'|'current') {
        if (type === 'next') counter++;
        if (type === 'prev') counter--;
        if (counter<0) counter = 0;
        if (counter> 22) counter = 22;
        return String.fromCharCode("A".charCodeAt(0) + counter);
    }
};

export type wayPointInfo = {
    distance:number,
    fromReg:string,
    toReg:string,
    insideMKAD:boolean,
    from: string ,
    to: string ,

}
