import H from "@here/maps-api-for-javascript";

var rect : H.map.Rect | null = null;
/**
 * Adds resizable geo rect to map
 *
 * @param {H.Map} map                      A HERE Map instance within the application
 * @param {H.mapevents.Behavior} behavior  Behavior implements default interactions with map
 * @param {number[]} coordinates  coordinates list
 */
export function createResizableRect(map: H.Map, behavior: any, coordinates: number[] = [51.2, 20.5, 49.5, 24.5]) {
    rect =  new H.map.Rect(
        new H.geo.Rect(coordinates[0], coordinates[1], coordinates[2], coordinates[3]),
        { data:{name: 'rectangle'},     style: {fillColor: 'rgba(100, 100, 100, 0.5)', lineWidth: 0} }
        );
    let rectOutline = new H.map.Polyline(
            //@ts-ignore
            rect.getGeometry().getExterior(),
            {  data:{name: 'rectangle'},
                style: {lineWidth: 5, strokeColor: 'rgba(255, 0, 0, 0)', fillColor: 'rgba(0, 0, 0, 0)', lineCap: 'square'}
            }
        );
    let rectGroup = new H.map.Group({  data:{name: 'rectangle'},
            volatility: true, // mark the group as volatile for smooth dragging of all it's objects
            objects: [rect, rectOutline]
        });
    let  rectTimeout:any = null;

    // ensure that the objects can receive drag events
    //@ts-ignore
    rect.draggable = true;
    //@ts-ignore
    rectOutline.draggable = true;

    // extract first point of the rect's outline polyline's LineString and
    // push it to the end, so the outline has a closed geometry
    //@ts-ignore
    rectOutline.getGeometry().pushPoint(rectOutline.getGeometry().extractPoint(0));

    // add group with rect and it's outline (polyline)
    map.addObject(rectGroup);

    // event listener for rectangle group to show outline (polyline) if moved in with mouse (or touched on touch devices)
    rectGroup.addEventListener('pointerenter', function(evt:any) {
        let currentStyle = rectOutline.getStyle();
        let newStyle = currentStyle?.getCopy({
                strokeColor: 'rgb(255, 0, 0)'
            });

        if (rectTimeout) {
            clearTimeout(rectTimeout);
            rectTimeout = null;
        }
        // show outline
        rectOutline.setStyle(newStyle);
    }, true);

    // event listener for rectangle group to hide outline if moved out with mouse (or released finger on touch devices)
    // the outline is hidden on touch devices after specific timeout
    rectGroup.addEventListener('pointerleave', function(evt:any) {
        var currentStyle = rectOutline.getStyle(),
            newStyle = currentStyle?.getCopy({
                strokeColor: 'rgba(255, 0, 0, 0)'
            }),
            timeout = (evt.currentPointer.type == 'touch') ? 1000 : 0;

        rectTimeout = setTimeout(function() {
            rectOutline.setStyle(newStyle);
        }, timeout);


        document.body.style.cursor = 'default';
    }, true);

    // event listener for rectangle group to change the cursor if mouse position is over the outline polyline (resizing is allowed)
    rectGroup.addEventListener('pointermove', function(evt:any) {
        let pointer = evt.currentPointer;
        let objectTopLeftScreen = map.geoToScreen(evt.target.getGeometry().getBoundingBox().getTopLeft()) as  H.math.Point ;
        let objectBottomRightScreen = map.geoToScreen(evt.target.getGeometry().getBoundingBox().getBottomRight()) as  H.math.Point ;
        let draggingType = '';

        // only set cursor and draggingType if target is outline polyline
        if (evt.target != rectOutline) {
            return;
        }

        // change document cursor depending on the mouse position
        if (pointer.viewportX < (objectTopLeftScreen.x + 4)) {
            document.body.style.cursor = 'ew-resize'; // mouse position is at left side
            draggingType = 'left';
        } else if (pointer.viewportX > (objectBottomRightScreen.x - 4)) {
            document.body.style.cursor = 'ew-resize'; // mouse position is at right side
            draggingType = 'right';
        } else if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
            document.body.style.cursor = 'ns-resize'; // mouse position is at top side
            draggingType = 'top';
        } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
            document.body.style.cursor = 'ns-resize'; // mouse position is at the bottom side
            draggingType = 'bottom';
        } else {
            document.body.style.cursor = 'default'
        }

        if (draggingType == 'left') {
            if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
                document.body.style.cursor = 'nwse-resize'; // mouse position is at the top-left corner
                draggingType = 'left-top';
            } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
                document.body.style.cursor = 'nesw-resize'; // mouse position is at the bottom-left corner
                draggingType = 'left-bottom';
            }
        }  else if (draggingType == 'right') {
            if (pointer.viewportY < (objectTopLeftScreen.y + 4)) {
                document.body.style.cursor = 'nesw-resize'; // mouse position is at the top-right corner
                draggingType = 'right-top';
            } else if (pointer.viewportY > (objectBottomRightScreen.y - 4)) {
                document.body.style.cursor = 'nwse-resize'; // mouse position is at the bottom-right corner
                draggingType = 'right-bottom';
            }
        }

        rectGroup.setData({'draggingType': draggingType});
    }, true);

    // disable the map's behavior if resizing started so map doesn't pan in the situation
    // when we try to set rect size to 0 or negative and mouse cursor leaves the map object
    rectGroup.addEventListener('dragstart', function(evt:any) {
        if (evt.target === rectOutline) {
            behavior.disable();
        }
    }, true);

    // event listener for rect group to resize the geo rect object if dragging over outline polyline
    rectGroup.addEventListener('drag', function(evt:any) {
        let pointer = evt.currentPointer;
        let  pointerGeoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY) as H.geo.Point  ;
        let currentGeoRect =   rect?.getGeometry()?.getBoundingBox() as H.geo.Rect;
        let objectTopLeftScreen = map.geoToScreen(currentGeoRect?.getTopLeft());
        let objectBottomRightScreen = map.geoToScreen(currentGeoRect.getBottomRight());

        // if pointer is over outline, resize the geo rect object
        if (evt.target instanceof H.map.Polyline) {
            var currentTopLeft = currentGeoRect.getTopLeft() as H.geo.Point ,
                currentBottomRight = currentGeoRect.getBottomRight() as H.geo.Point ,
                newGeoRect:  H.geo.Rect | null = null,
                outlineLinestring;

            // update rect's size depending on dragging type:
            switch(rectGroup.getData()['draggingType']) {
                case 'left-top':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
                        return;
                    }
                    newGeoRect = H.geo.Rect.fromPoints(pointerGeoPoint, currentGeoRect.getBottomRight()) as  H.geo.Rect;
                    break;
                case 'left-bottom':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng >= currentBottomRight.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
                        return;
                    }
                    currentTopLeft.lng = pointerGeoPoint.lng;
                    currentBottomRight.lat = pointerGeoPoint.lat;
                    newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight);
                    break;
                case 'right-top':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat <= currentBottomRight.lat) {
                        return;
                    }
                    currentTopLeft.lat = pointerGeoPoint.lat;
                    currentBottomRight.lng = pointerGeoPoint.lng;
                    newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentBottomRight) as  H.geo.Rect;
                    break;
                case 'right-bottom':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng <= currentTopLeft.lng || pointerGeoPoint.lat >= currentTopLeft.lat) {
                        return;
                    }
                    newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), pointerGeoPoint) as  H.geo.Rect;
                    break;
                case 'left':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng >= currentBottomRight.lng) {
                        return;
                    }
                    currentTopLeft.lng = pointerGeoPoint.lng;
                    newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight()) as  H.geo.Rect;
                    break;
                case 'right':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lng <= currentTopLeft.lng) {
                        return;
                    }
                    currentBottomRight.lng = pointerGeoPoint.lng;
                    newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight) as  H.geo.Rect;
                    break;
                case 'top':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lat <= currentBottomRight.lat) {
                        return;
                    }
                    currentTopLeft.lat = pointerGeoPoint.lat;
                    newGeoRect = H.geo.Rect.fromPoints(currentTopLeft, currentGeoRect.getBottomRight()) as  H.geo.Rect;
                    break;
                case 'bottom':
                    // we don't allow resizing to 0 or to negative values
                    if (pointerGeoPoint.lat >= currentTopLeft.lat) {
                        return;
                    }
                    currentBottomRight.lat = pointerGeoPoint.lat;
                    newGeoRect = H.geo.Rect.fromPoints(currentGeoRect.getTopLeft(), currentBottomRight) as  H.geo.Rect;
                    break;
            }

            // set the new bounding box for rect object
            rect?.setBoundingBox(newGeoRect  as  H.geo.Rect);

            // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
            //@ts-ignore
            outlineLinestring = rect.getGeometry().getExterior();
            outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
            rectOutline.setGeometry(outlineLinestring);

            // prevent event from bubling, so map doesn't receive this event and doesn't pan
            evt.stopPropagation();
        }
    }, true);

    // event listener for rect group to enable map's behavior
    rectGroup.addEventListener('dragend', function(evt:any) {
        // enable behavior
        behavior.enable();
        let rb = rect?.getBoundingBox()?.getBottomRight();
        let tl = rect?.getBoundingBox()?.getTopLeft();

        console.log( '['   +  tl?.lat  + "," +  tl?.lng + ","  +  rb?.lat  + "," +  rb?.lng  +  ']' )
    }, true);
}


export function addRectangleToMap(map:H.Map, coordinates:number[], name:string) {
    var boundingBox = new H.geo.Rect(coordinates[0],coordinates[1], coordinates[2], coordinates[3]);
   // var boundingBox = new H.geo.Rect(53.1, 13.1, 43.1, 40.1);
    map.addObject(
        new H.map.Rect(boundingBox, {
            data:{name: name},
            style: {
                fillColor: '#FFFFCC',

            },
        })
    );
}
