import HereMarkerIcon from "./HereMarkerIcon";
import {appendPoint, ROAD_MKAD, setMessage, setResult, setRoute} from "../../../redux/mapReducer";
import H from "@here/maps-api-for-javascript";
import {calculateGeoPath, CoordinatePoint} from "./CalculateGeometry";
import {MapPoint} from "../../../redux/mapTypes";

const  ROUTE_LINE = 'route_line';

export function hereMarker (label:string, coordination: number[], index:number , point: MapPoint | null = null) {
    const labelAlt = String.fromCharCode("A".charCodeAt(0) + index);
    let marker : H.map.DomMarker = new H.map.DomMarker({lat:coordination[0], lng:coordination[1]}
    , { data: null,
        icon: new H.map.DomIcon(
            HereMarkerIcon.replace('{NUMBER}', label? label : labelAlt)
        )
    });
    // @ts-ignore
    marker.draggable = true;
    marker.setVolatility(true);
    marker.setZIndex(index);

    return marker;
}

export function setDraggable(map: H.Map, behavior:any, callback: (map: H.Map) => void ) {
    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener('dragstart', function(ev :any) {
        var target = ev.target;
        if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
            behavior.disable();
        }
    }, false);

    map.addEventListener('dragend', function(ev:any) {
        var target = ev.target;
        if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
            behavior.enable();
            callback(map);
        }
    }, false);

    map.addEventListener('drag', function(ev:any) {
        var target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
            target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY + 20));
        }
    }, false);
}

export function afterDrag(map: H.Map, points:MapPoint[], platform: H.service.Platform, dispatch:any) {

    let markers = map.getObjects();
    markers.forEach((item) => {
        if (item instanceof H.map.Marker || item instanceof H.map.DomMarker) {
            let index = item.getZIndex();
            if (index === undefined || index < 0)
                return;
            if (item?.getData()?.name == "marker_mkad") return;
            let geo : H.geo.Point = item.getGeometry() as H.geo.Point;
            if (points[index] && (points[index].lat !== geo.lat
                || points[index].lng !== geo.lng)) {
                geocode([geo.lat, geo.lng], platform, dispatch, index);
            }
        }
    });
}


export function geocode(cord:number[], platform: H.service.Platform, dispatch: any, index:number  ) {
    var geocoder = platform.getSearchService(),
        reverseGeocodingParameters = {
            at: cord[0] + ',' + cord[1],
            limit: '1'
        };

    geocoder.reverseGeocode(
        reverseGeocodingParameters,
        (data) => {
            dispatchAddress(data, cord, dispatch, index);
            console.log('reverseGeocode data=',data)
        },
        (data) => {
            console.error(data);
        }
    );
}

let oldWaypointsString = '';
/**
 * A full list of available request parameters can be found in the Routing API documentation.
 * see:  http://developer.here.com/rest-apis/documentation/routing/topics/resource-calculate-route.html
 *
 * @param   {H.service.Platform} platform    A stub class to access HERE services
 * @param waypoints {array} ['lat,lon', 'lat,lon']
 * @param map {H.Map}
 * @param params {}
 */
export  const  calculateRoute = (platform: H.service.Platform, waypoints: string[], map:H.Map,
                                      params : {
                                                    avoidPoints?:string,
                                                    departureTime?:string,
                                                    routingMode?:string,
                                                    avoidFeatures?:string,
                                                    useJams?:boolean,
                                                    paramsTruck?:{name:string, value:string}[],
                                                    carType: string
                                                }

) => async (dispatch:any, useState:any) => {

    if (waypoints.length<2) return ;

    let router = platform.getRoutingService();
    //let router = platform.getRoutingService({}, 8);
    // let routeRequestParams : any = {
    //         transportMode: 'truck',
    //         origin: waypoints.shift(),
    //         destination: waypoints.pop(),
    //         return: 'polyline,travelSummary',
    //         lang: 'ru-RU',
    //
    //      };
    let {avoidPoints, departureTime, routingMode, avoidFeatures, useJams, paramsTruck, carType } = {...params};

    var routeRequestParams : any = {
        representation: 'display',
        routeattributes : 'all', //waypoints,summary,shape,legs,
        linkattributes : 'all', //waypoints,summary,shape,legs,
        legattributes : 'all', //waypoints,summary,shape,legs,
        maneuverattributes : 'all', //waypoints,summary,shape,legs,
        mode: carType+";traffic:enabled;",
        language:'ru-RU',
        truckRestrictionPenalty: carType === 'truck' ? 'strict' : '',
        trailersCount: carType === 'truck' ? 1 : '',
        transportMode: carType === 'publicTransportTimeTable' || carType === 'publicTransport'  ? 'publicTransport' : carType
    };



    waypoints.forEach(
        (point,index)=> routeRequestParams['waypoint'+ index] = point
    )
    if (avoidPoints && avoidPoints !== "") {
        routeRequestParams["avoidareas"] = avoidPoints;
        // routeRequestParams["avoid[areas]"] = avoidPoints;
    }

    // if (departureTime && departureTime !== "") {
    //     routeRequestParams["departureTime"] = departureTime+":00+03:00";
    // }

    if (departureTime && departureTime !== "") {
        routeRequestParams["departure"] = departureTime+":00";
    }


    // if (avoidFeatures && avoidFeatures !== "") {
    //     routeRequestParams["avoid[features]"] = avoidFeatures;
    // }

    if (paramsTruck && paramsTruck.length) {
        paramsTruck.forEach(x=>  routeRequestParams[  x.name  ] = x.value );
    }
    //        mode: 'fastest;truck;traffic:enabled',


    if (routingMode && routingMode !== "") {
        routeRequestParams["mode"]  =  routingMode + routeRequestParams["mode"];
    }

    if (avoidFeatures && avoidFeatures !== "") {
        routeRequestParams[ "mode" ] += avoidFeatures ;
    }

    // let t:string = routeRequestParams[ "mode" ];
    // routeRequestParams[ "mode" ]= t.length ?  t.substr(0, t.length -1) : t;
    console.log(routeRequestParams);
    // if (waypoints.length > 1) {
    //     // @ts-ignore
    //     routeRequestParams.via =  new H.service.Url.MultiValueQueryParameter(waypoints);
    // } else if (waypoints.length === 1) {
    //     // @ts-ignore
    //     routeRequestParams.via =   waypoints.pop() ;
    // }

   // await asyncCalculateRouter(router, routeRequestParams, map);
   // router.calculateRoute(
   //      routeRequestParams,
   //      (result) => {
   //          onSuccess(result, map);
   //      },
   //      (d) => { console.error(d); }
   //  );
    let wPoint :CoordinatePoint[]=[];
    waypoints.forEach( x=> {
        let arr = x.split(',').map(x=> +x);
        if (arr.length)
         wPoint.push( [arr[1], arr[0]]);
    });
    return new Promise( (resolve, reject) => {
        router.calculateRoute(
            routeRequestParams,
            (result) => {
                onSuccess(result, map, dispatch, wPoint, routeRequestParams, platform);

                resolve(result);

            },
            (d) => { dispatch(setMessage('Ошибка построения маршрута!'));  resolve(null); }
        );
    } )
}

export function getWayFromPointToPoint(pointA:number[], pointB:number[], routeRequestParams: any, platform: H.service.Platform, map:H.Map, needDrawWay = true){
    let router = platform.getRoutingService();

    routeRequestParams.waypoint0 = pointA[1]+ ',' + pointA[0];
    routeRequestParams.waypoint1 = pointB[1]+ ',' + pointB[0];
    routeRequestParams["avoidareas"] = '';

    let index = 2;
    while ( ("waypoint" + index) in routeRequestParams) {
        routeRequestParams["waypoint" + index] = '';
        index++;
    }

    return new Promise( (resolve, reject) => {
        router.calculateRoute(
            routeRequestParams,
            (result : any) => {

                if (result && result.response && result.response.route ) {
                    let route = result.response.route[0];
                    let lineLonLat = addRouteShapeToMap(route, map, 3, 'rgba(243, 31, 31, 0.6)', false, needDrawWay);
                    resolve(lineLonLat);
                } else
                resolve([]);
            },
            (d) => { resolve([]); }
        );
    } )
}
/**
 * This function will be called once the Routing REST API provides a response
 * @param  {Object} result          A JSONP object representing the calculated route
 *
 * see: http://developer.here.com/rest-apis/documentation/routing/topics/resource-type-calculate-route.html
 * @param map {H.Map}
 * @param dispatch диспатчер
 * @param wayPoints точки маршрута
 */
function onSuccess(result:any , map:H.Map, dispatch: any, wayPoints:CoordinatePoint[], routeRequestParams:Object, platform: H.service.Platform ) {
    // console.log(result, wayPoints);
    if (result && result.response && result.response.route ) {
        let route = result.response.route[0];
        let lineLonLat = addRouteShapeToMap(route, map);
        // console.log(lineLonLat, wayPoints, route.waypoint);
        calculateGeoPath(lineLonLat, wayPoints, route.waypoint,dispatch, routeRequestParams, platform, map )
        dispatch(setMessage(''));
        dispatch(setResult(route.summary));
        dispatch(setRoute(route));
        if (    route.leg && route.mode && route.mode.transportModes &&
                (route.mode.transportModes as Array<string>).some(xx => xx === "publicTransportTimeTable" || xx === "publicTransport")
           )
            dispatch(setRoute(route));
        else
            dispatch(setRoute(null));
    }
}

/**
 * Creates a H.map.Polyline from the shape of the route and adds it to the map.
 * @param {Object} route A route as received from the H.service.RoutingService
 * @param map
 * @param wayPoints точки маршрута
 */
function addRouteShapeToMap(route:any, map:H.Map,  lineWidth:number=5, color:string = 'rgba(72, 148, 224, 0.8)', removeOldObjects = true, needDrawWay = true){
   if (removeOldObjects) removeObjectFromMap(map, ROUTE_LINE);
    // let lineString = new H.geo.LineString(route.shape,  'values lat lng');

    let line: number[] = [];
    let lineLonLat : CoordinatePoint[] = [];
        route.shape.forEach( (x:any) => {
                let points = x.split(',');
                line = line.concat(points.concat(100));

                lineLonLat.push( [+points[1], +points[0]]);
            });
    if (!needDrawWay) return lineLonLat;
    let lineString = new H.geo.LineString(
        line,
        // @ts-ignore
        ' lat lng '
    );

    let p =  new H.map.Polyline( lineString, { data:{name: ROUTE_LINE}, style: { lineWidth: lineWidth, strokeColor: color}});
    map.addObject(p);
    return lineLonLat;
}

export function drawWayToMKAD(points: CoordinatePoint[], map: H.Map) {

    var lineString = new H.geo.LineString();

    points.forEach( point => lineString.pushPoint({lat:point[1], lng:point[0]}));

    map.addObject(new H.map.Polyline(
        lineString, { data:{name: ROUTE_LINE}, style: { lineWidth: 3, strokeColor: 'rgba(224, 31, 31, 0.6)' }}
    ));
}

export function drawWayOnMap(points: MapPoint[], map: H.Map) {
    let newWaypointsString = JSON.stringify(points.map( x => x.lng + x.lat));
    if ( oldWaypointsString === newWaypointsString) return;

    oldWaypointsString = newWaypointsString;

    oldWaypointsString = '';

    // let objects: H.map.Object[] = map.getObjects().filter( obj => !(obj instanceof H.map.DomMarker || obj instanceof H.map.Marker)) as H.map.Object[];
    // map.removeObjects(objects);

    var lineString = new H.geo.LineString();

    points.forEach( point => lineString.pushPoint({lat:point.lat, lng:point.lng}));

    map.addObject(new H.map.Polyline(
        lineString, { data:null, style: { lineWidth: 5, strokeColor: 'rgba(224, 148, 72, 0.8)' }}
    ));
}

export function removeObjectFromMap( map: H.Map, nameObject:string) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];

    let objects: H.map.Object[] = listObj.filter( (obj : H.map.Object) => {

        // @ts-ignore
        if (obj.data && obj.data.name === nameObject) return true;
        return  false;
    });
    if (objects && objects.length)
        map.removeObjects(objects);
}
export function drawLineOnMap(points: [number,number][], map: H.Map, nameObject:string) {

    removeObjectFromMap(map, nameObject);

    var lineString = new H.geo.LineString();

    points.forEach( point => lineString.pushPoint({lat:point[1], lng:point[0]}));

    map.addObject(new H.map.Polyline(
        lineString, { data:{name:nameObject}, style: { lineWidth: 5, strokeColor: 'rgba(0, 148, 0, 0.8)' }}
    ));
}

export function getPointText(label:string) {
    return `<b>${label}</b>`;
}


export function dispatchAddress(data:any, coord: number[], dispatch:any, index:number) {
    let dataAddress = {};
    if (data.items && data.items.length &&  data.items[0].address) {
        let address = data.items[0].address;
        dataAddress = {
                region_type: '',
                region: address.county,
                street_type: '',
                street: address.street,
                house: address.houseNumber,
                label: address.label
        }
    }

    dispatch(appendPoint({ lat: coord[0], lng: coord[1], data:dataAddress }, index));
}
