import React from 'react';
import Loader from 'react-loader-spinner';

const WindowLoader = () => {
    return (
            <div className='messageInfo loading'>
                <Loader type="Puff" color="#ffb700" height={40} width={40}/>
                <div className='loadingText'>
                    построение маршрута
                </div>
            </div>
    );
}

export default WindowLoader;
