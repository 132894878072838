import {
    PropsMapDisplayResultType
} from "../components/MapApp/MapDisplayResult/MapDisplayResult";

import {wayPointInfo} from "../components/MapApp/MapHereCom/CalculateGeometry";
import {
    APPEND_POINT,

    InitialStateMapReducerType,
    MapPoint,
    MapReducerActionType,
    REMOVE_POINT,
    removePointActionType, RoadsSelected, RouteType,
    SET_AVOID_FEATURES,
    SET_CALC_WAY_POINT,
    SET_CAR_TYPE,
    SET_DATE_TIME,
    SET_JAMS,
    SET_MESSAGE,
    SET_PARAM_ROUTING,
    SET_POINTS,
    SET_RESULT,
    SET_ROAD_CHECKED, SET_ROUTE,
    SET_ROUTE_LOADING,
    SET_ROUTING_MODE,
    setAvoidFeaturesActionType,
    setCalculatedWayPointsActionType,
    setCarTypeActionType,
    setDateTimeActionType,
    setJamsActionType,
    setMessageActionType, setParamsTruckActionType,
    setParamsTruckRoadActionType,
    setPointsActionType,
    setResultActionType, setRouteActionType,
    setRouteLoadingActionType,
    setRouteModeActionType,
    setSelectedRoadActionType,
    UPDATE_POINT, updatePointActionType
} from "./mapTypes";

export const ROAD_MKAD = 'mkad';

export const ROAD_TTK = 'ttk';
export const ROAD_SAD = 'sad';
export const ROAD_CKAD = 'ckad';



let initialState: InitialStateMapReducerType = {
    routeLoading: false,
    points:[],
    road_selected : {
        mkad:false,
        ttk:false,
        sad:false,
        ckad:false,
    },
    avoid_features: [],
    params_truck: [],
    routing_mode:'balanced;',
    date_time_iso:'',
    use_jams: true,
    result: null,
    route: null,
    message: '',
    car_type: 'truck',
    wayPointsInfo:[]
};

let mapReducer = (state = initialState, action: MapReducerActionType) : InitialStateMapReducerType => {
    switch (action.type) {

        case SET_ROUTE_LOADING:
            return {
                ...state,
                routeLoading: action.value
            };

        case SET_ROUTING_MODE:
            return {
                ...state,
                routing_mode: action.value ? 'balanced;' : 'fastest;'
            };

        case SET_DATE_TIME:
            let a = action as setDateTimeActionType;
            return {
                ...state,
                date_time_iso: a.value
            };

        case SET_POINTS:
            return {
                ...state,
                points:  action.points_array
            };

        case APPEND_POINT:
            return {
                ...state,
                points: [...state.points, action.value.point]
            };

        case UPDATE_POINT:

            let updPoints : MapPoint[] = [...state.points];
            if ( +action.value.index < state.points.length)
                updPoints[+action.value.index] = {...action.value.point};
            return {
                ...state,
                points: updPoints
            };

        case REMOVE_POINT:

            return {
                ...state,
                points: action.value.index < state.points.length ? [...state.points].slice(action.value.index, 1) :  state.points
            };

        case SET_ROAD_CHECKED:
            let src = action as setSelectedRoadActionType;
            let road_selected : RoadsSelected = {...state.road_selected};
            if ((action as setSelectedRoadActionType).value.name === ROAD_MKAD || (action as setSelectedRoadActionType).value.name === ROAD_TTK
                ||(action as setSelectedRoadActionType).value.name === ROAD_SAD ||(action as setSelectedRoadActionType).value.name === ROAD_CKAD) {
                 // @ts-ignore
                road_selected[src.value.name] = (action as setSelectedRoadActionType).value.is_checked;
            }
            return {
                ...state,
                road_selected: road_selected
            };

        case SET_AVOID_FEATURES:

            let avoid_features : string[] = [...state.avoid_features];
            if (action.value?.is_checked) avoid_features.push(action.value.name) ;
            else avoid_features = avoid_features.filter(x => x !==  action.value.name);
            return {
                ...state,
                avoid_features: avoid_features
            };

        case SET_PARAM_ROUTING:
            let v = (action as setParamsTruckRoadActionType).value;
            let params : {name:string,value:string}[] = [...state.params_truck];
            if (v.value=='' || +v.value<=0 )  params = params.filter(x => x.name != v.name );
            else params.push(v) ;
            return {
                ...state,
                params_truck: params
            };

        case SET_JAMS:
            return {
                ...state,
                use_jams: action.value
            };

        case SET_RESULT:
            return {
                ...state,
                result: action.value
            };

        case SET_ROUTE:
            return {
                ...state,
                route: action.route
            };

        case SET_CALC_WAY_POINT:
            return {
                ...state,
                wayPointsInfo: [...action.value]
            };
        case SET_MESSAGE: return {...state, message: action.value as string};
        case SET_CAR_TYPE: return {...state, car_type: action.value as string};

        default:
            return state;
    }
};


export const setRouteLoading = (value:boolean) : setRouteLoadingActionType => ({type: SET_ROUTE_LOADING, value});


export const appendPoint = (point:MapPoint, index:number)  => (dispatch : any, getState:any) : any => {
    if (index < getState().mapReducer.points.length) {
        dispatch({type: UPDATE_POINT, value: {point, index}});
        return;
    }
    dispatch({type: APPEND_POINT, value: {point, index}});
} ;
export const setPoints = (points: MapPoint[]) : setPointsActionType =>  ({type: SET_POINTS, points_array: points});
export const updatePoint = (point: MapPoint, index:number) : updatePointActionType =>  ({type: UPDATE_POINT, value: {point, index}});
export const removePoint = (index:number) : removePointActionType =>  ({type: REMOVE_POINT, value: {point: {lat:0,lng:0},index}});

export const setSelectedRoad = (name:string, value:boolean) : setSelectedRoadActionType =>  ({type: SET_ROAD_CHECKED, value:{is_checked:value, name}});
export const setAvoidFeatures = (name:string, value:boolean) : setAvoidFeaturesActionType =>  ({type: SET_AVOID_FEATURES, value:{is_checked:value, name}});
export const setParamsTruck = (name:string, value:string) : setParamsTruckActionType =>  ({type: SET_PARAM_ROUTING, value:{value, name}});
export const setRoutingMode = (value:boolean) : setRouteModeActionType =>  ({type: SET_ROUTING_MODE, value });
export const setDateTime = (value:string) : setDateTimeActionType =>  ({type: SET_DATE_TIME, value });
export const setJams = (value:boolean) : setJamsActionType =>  ({type: SET_JAMS, value });


export const setResult = (value: PropsMapDisplayResultType | null) : setResultActionType =>  ({type: SET_RESULT, value });
export const setRoute = (route: RouteType | null ) : setRouteActionType =>  ({type: SET_ROUTE, route });

export const setMessage = (value:string) : setMessageActionType =>  ({type: SET_MESSAGE, value });
export const setCarType = (value:string) : setCarTypeActionType =>  ({type: SET_CAR_TYPE, value });
export const setCalculatedWayPoints = (value:wayPointInfo[]) : setCalculatedWayPointsActionType =>  ({type: SET_CALC_WAY_POINT, value });


// export const setWayPoints = (points:[]) => async (dispatch) => {
//     let pointsLocal = points.map(point => point.address.longitude ? [point.address.longitude, point.address.latitude] : []);
//     if (pointsLocal.length) dispatch(setWaypointsThunk(pointsLocal.filter((a) => a.length), ''));
// };
//
// export const setWayPointsThunk = (coords, exclude) => async (dispatch, getState) => {
//     let state = getState().mapReducer;
//     dispatch(setMapState({
//         segments: [],
//         mkadRoutes: {},
//         exclude: exclude,
//         waypoints: coords
//     }));
//
//     if (coords.length <= 1) {
//         return;
//     }
//
//     const box = bbox(lineString(coords));
//     await dispatch(setMapState({bounds: [[box[0], box[1]], [box[2], box[3]]]}));
//
//     // await getRoutes(coords, exclude, state, dispatch);
// };


export default mapReducer;
