import React, {useEffect, useState} from "react";
import H from "@here/maps-api-for-javascript";
import {hereMapApiKey} from "../../../deployment";
import "./AutosuggestInput.css"
import {map} from '../MapHereCom/HereMapContainer'
import {removeObjectFromMap} from "../MapHereCom/hereFunctions";

type Props =
    {

    }

let AutosuggestInput : React.FC<Props> = ({})  => {
    const [address, setAddress] = useState<string>('');
    const [addressList, setAddressList] = useState<{ position:Object,title: string}[]>([]);


    useEffect(() => {



    }, []);

    const selectAddress = (address:{ position:any,title: string}) =>{
        setAddress(address.title);
        setAddressList([]);
        removeObjectFromMap( map, 'address');

        let marker = new H.map.Marker(address.position,{data: {name: 'address'} });
        map.addObject(marker);
        map.getViewModel().setLookAtData({
            position: address.position
        });

    };

    const loadAddressList = (q:string) =>{
        setAddress(q);
        const platform = new H.service.Platform({
            'apikey': hereMapApiKey
        });
        var service = platform.getSearchService();
        if (q == '') q = 'Москва';
        // Call the "autosuggest" method with the search parameters,
        // the callback and an error callback function (called if a
        // communication error occurs): lat: , lng:
        service.autosuggest({
            // Search query
            q: q,
            // Center of the search context
            at: '55.75602,37.61882',
            in: 'countryCode:RUS'
        }, (result) => {
            setAddressList([]);
            // @ts-ignore
            if (result && result.items)
                // @ts-ignore
                setAddressList(result?.items.map( (x:any) => ({position:x.position, title:x.title})));

        }, alert);
    };

    return (
            <>
                <div className="autosuggestValue">
                    <input onChange={(e)=>{
                        loadAddressList(e.target.value);
                    }}
                           value={address}
                           placeholder={'Введите адрес ...'}
                    />
                </div>

                <div className={addressList.length? 'listAutosuggest' : 'listAutosuggestHide'}>
                {
                    addressList.length &&
                        addressList.map( (x,index)=><div key={'rowItem' + index} className="rowItem" onClick={(e)=>{ selectAddress(x) }}>{x.title}</div> )
                }
                </div>
            </>
        )
};

export default AutosuggestInput
