import {
    PropsMapDisplayResultType
} from "../components/MapApp/MapDisplayResult/MapDisplayResult";

import {wayPointInfo} from "../components/MapApp/MapHereCom/CalculateGeometry";

export const SET_ROUTE_LOADING = 'SET_ROUTE_LOADING';
export const APPEND_POINT = 'APPEND_POINT';
export const UPDATE_POINT = 'UPDATE_POINT';
export const REMOVE_POINT = 'REMOVE_POINT';
export const SET_ROAD_CHECKED = 'SET_ROAD_CHECKED';
export const SET_PARAM_ROUTING = 'SET_PARAM_ROUTING';
export const SET_AVOID_FEATURES = 'SET_AVOID_FEATURES';
export const SET_ROUTING_MODE = 'SET_ROUTING_MODE';
export const SET_DATE_TIME = 'SET_DATE_TIME';
export const SET_JAMS = 'SET_JAMS';
export const SET_RESULT = 'SET_RESULT';
export const SET_ROUTE = 'SET_ROUTE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_CAR_TYPE = 'SET_CAR_TYPE';
export const SET_POINTS = 'SET_POINTS';
export const SET_CALC_WAY_POINT = 'SET_CALC_WAY_POINT';

export type setPointsActionType = {type: typeof SET_POINTS, points_array: MapPoint[]}
export type updatePointActionType = {type:typeof  UPDATE_POINT, value: {point: MapPoint, index:number}}
export type appendPointActionType = {type:typeof  APPEND_POINT, value: {point: MapPoint, index:number}}
export type removePointActionType = {type:typeof  REMOVE_POINT, value: {point: MapPoint, index:number}}

export type setRouteLoadingActionType = {type: typeof SET_ROUTE_LOADING, value:boolean}
export type setRouteModeActionType = {type: typeof SET_ROUTING_MODE, value:boolean}
export type setJamsActionType = {type: typeof SET_JAMS, value:boolean}

export type setResultActionType = {type: typeof SET_RESULT, value: PropsMapDisplayResultType | null}
export type setRouteActionType = {type: typeof SET_ROUTE, route: RouteType | null}
export type setDateTimeActionType = {type: typeof SET_DATE_TIME, value: any}
export type setMessageActionType = {type: typeof SET_MESSAGE, value: any}
export type setCarTypeActionType = {type:typeof SET_CAR_TYPE, value: any}
export type setCalculatedWayPointsActionType = {type:typeof SET_CALC_WAY_POINT, value: wayPointInfo[]}

export type setSelectedRoadActionType = {type: typeof SET_ROAD_CHECKED, value: { is_checked: boolean, name: string}}
export type setAvoidFeaturesActionType = {type: typeof SET_AVOID_FEATURES, value: { is_checked: boolean, name: string}}
export type setParamsTruckActionType = {type: typeof SET_PARAM_ROUTING, value: { value: string, name: string}}
export type setParamsTruckRoadActionType = {type:string, value: { value: string, name: string}}


export type MapPoint = {
    lng:number,
    lat:number,
    data?:{
        region_type?: string,
        region?: string,
        street_type?: string,
        street?: string,
        house?: string,
        label?: string
    }
}

export type RoadsSelected = {
    mkad:boolean,
    ttk:boolean,
    sad:boolean,
    ckad:boolean,
}

export type ParamsSelected = {
    traffic:boolean,
    fastest:boolean,
    sad:boolean,
    ckad:boolean,
}

export type LegType = {
    start:  object,
    end: object,
    length: number,
    travelTime: number,
    maneuver:{
        position: {
            latitude: number,
            longitude: number
        },
        instruction: string,
        travelTime: number,
        length: number,
        shape: string[],
        firstPoint: number,
        lastPoint: number,
        time: string,
        note: any[],
        nextManeuver: string,
        toLink: string,
        boundingBox: object,
        shapeQuality: string,
        direction: string,
        action: string,
        roadName: string,
        nextRoadName: string,
        roadNumber: string,
        nextRoadNumber: string,
        baseTime: number,
        startAngle: number,
        stopName: string,
        line: string,
        accessPointName: string,
        id: string,
        _type: string,
    }[],
    shape: string[],
    firstPoint: number,
    lastPoint: number,
    baseTime: number,
    stayingTime: number,
    summary: {
        distance: number,
        baseTime: number,
        text: string,
        travelTime: number,
        _type: string
    }
};

export type publicTransportLine ={
    lineName: string,
    lineForeground: string,
    lineBackground: string,
    companyName: string,
    destination: string,
    type: string,
    id: string,
};
export type RouteType = {
    waypoint:any[],
    mode:object,
    boundingBox:boolean,
    leg: LegType[],
    publicTransportLine:publicTransportLine[],
    note:any[],
    summary:{
        distance : number,
        "baseTime": number,
        "flags": string[ ],
        "text": string,
        "travelTime": number,
        "departure": string,
        "timetableExpiration": string,
        "_type": string
    },
}




export type InitialStateMapReducerType = {
    routeLoading: boolean,
    points: MapPoint[],
    road_selected: RoadsSelected
    avoid_features:  string[],
    params_truck:  {name:string,value:string}[],
    routing_mode:string,
    date_time_iso: string,
    use_jams: boolean,
    result: PropsMapDisplayResultType | null;
    route: RouteType | null;
    message:string,
    car_type:string,
    wayPointsInfo: wayPointInfo[]
}

export type MapReducerActionType = setPointsActionType
    |  setRouteLoadingActionType
    | appendPointActionType
    | updatePointActionType
    | removePointActionType
    | setSelectedRoadActionType
    // | setParamsTruckRoadActionType
    | setRouteActionType
    | setDateTimeActionType
    | setResultActionType
    | setRouteModeActionType
    | setJamsActionType
    | setMessageActionType
    | setCarTypeActionType
    | setCalculatedWayPointsActionType
    | setAvoidFeaturesActionType
    | setParamsTruckActionType

    ;
