import React, {useEffect, useRef} from 'react'
import './styles.css'
import MapDisplayResult, { PropsMapDisplayResultType} from "./MapDisplayResult/MapDisplayResult";
import HereMapContainer from "./MapHereCom/HereMapContainer";
import {useDispatch, useSelector} from "react-redux";
import {
    ROAD_CKAD,
    ROAD_MKAD,
    ROAD_SAD,
    ROAD_TTK, setAvoidFeatures,
    setDateTime, setParamsTruck,
    setRoutingMode,
    setSelectedRoad,
    setCarType
} from "../../redux/mapReducer";
import WindowLoader from "./Loader/WindowLoader";
import {AppStateType} from "../../redux/store";
import AutosuggestInput from "./AutosuggestInput/AutosuggestInput";
import MapCalculateResult from "./MapCalculateResult/MapCalculateResult";
import {wayPointInfo} from "./MapHereCom/CalculateGeometry";
import {RouteType} from "../../redux/mapTypes";


type PropsType ={
    isLoading: boolean;
}


let MapApp: React.FC<PropsType> = () => {

    const dispatch = useDispatch();

    const {isLoading, result, route, message, wayPointsInfo} = useSelector<AppStateType, { isLoading: boolean, result: PropsMapDisplayResultType, route: RouteType | null, message: string, wayPointsInfo:wayPointInfo[]}> ((state: AppStateType) =>
            ({ isLoading: state.mapReducer.routeLoading,
                result: state.mapReducer.result as PropsMapDisplayResultType,
                route: state.mapReducer.route,
                message: state.mapReducer.message,
                wayPointsInfo:  state.mapReducer.wayPointsInfo
            }));

    const setNumber = (e : any,name:string) => {
        if (+e.target.value > +e.target.max)   e.target.value = e.target.max ;
        dispatch(setParamsTruck(name,   e.target.value))
    };


    return (
        <div className='container'>
            <MapCalculateResult wayPointsInfo={wayPointsInfo}/>
            <div className='leftSide'>
                {
                    isLoading ?  <WindowLoader/> : (result || message) && <MapDisplayResult result={result} route={route} message={message}  />
                }
                {
                    !result
                    && (!message || message=='') && <div className="resultInfo center labelInfo" >Расчет маршрута грузового транспорта</div>
                }

                <div className='checkboxDiv'><AutosuggestInput/></div>


                <div className='checkboxes'>
                    <div className='checkboxDiv'><label className='inputTime'><input type='datetime-local' onChange={(e)=>{
                        dispatch(setDateTime(e.target.value)); console.log(e.target.value) }} /></label></div>

                </div>

                <div className='checkboxesSplitter'/>
                <div className='checkboxes'>
                    <div className='checkboxDivTitle'>Тип транспортного средства</div>
                    <div className='checkboxDiv justifyDiv'>
                        <label><input type='radio' name="ct" defaultChecked={true} onChange={(e)=> dispatch(setCarType(  "truck"   ))}  />грузовой</label>
                        <label><input type='radio' name="ct"  onChange={(e)=> dispatch(setCarType( 'car' ))}   />легковой</label>
                        <label><input type='radio' name="ct"  onChange={(e)=> dispatch(setCarType( 'bicycle' ))}   />велосипед</label>
                        <label><input type='radio' name="ct"  onChange={(e)=> dispatch(setCarType( 'pedestrian' ))}   />пешеходный</label>
                        <label><input type='radio' name="ct"  onChange={(e)=> dispatch(setCarType( 'publicTransport' ))}   />общ. транспорт</label>
                        <label className="fullWidth"><input type='radio' name="ct"  onChange={(e)=> dispatch(setCarType( 'publicTransportTimeTable' ))}  />общ. транспорт с учетом расписания (нужно выбрать время отправления)</label>
                    </div>
                </div>

                <div className='checkboxesSplitter'/>
                <div className='checkboxes'>
                    <div className='checkboxDivTitle'>Исключить при построении маршрута</div>
                    {/*<div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setSelectedRoad("traffic", e.target.checked))}/>Пробки</label></div>*/}
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setAvoidFeatures("tollroad:-3", e.target.checked))}  />Платные дороги</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setAvoidFeatures("dirtRoad:-3", e.target.checked))} />Грунтовые дороги</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setAvoidFeatures("tunnel:-3", e.target.checked))} />Туннели</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setAvoidFeatures("motorway:-3", e.target.checked))} />Шоссе</label></div>
                    {/*<div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setJams(e.target.checked))} />Пробки</label></div>*/}
                </div>
                <div className='checkboxesSplitter'/>
                <div className='checkboxes'>
                    <div className='checkboxDivTitle'>Расчет маршрута</div>
                    <div className='checkboxDiv'><label>
                        <input type='radio' defaultChecked={true} name='rb'
                               onChange={(e)=> dispatch(setRoutingMode(true))}/>сбалансированный</label>
                    </div>
                    <div className='checkboxDiv'><label>
                        <input type='radio' name='rb'
                               onChange={(e)=> dispatch(setRoutingMode(false))}/>по наименьшему времени</label>
                    </div>

                </div>

                <div className='checkboxesSplitter'/>
                <div className='checkboxes'>
                    <div className='checkboxDivTitle'>Учитывать ограничения для грузового транспорта</div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setSelectedRoad(ROAD_TTK, e.target.checked))}/>Москва - ТТК</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setSelectedRoad(ROAD_MKAD, e.target.checked))} />Москва - МКАД</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setSelectedRoad(ROAD_SAD, e.target.checked))} />Москва - Садовое кольцо</label></div>
                    <div className='checkboxDiv'><label><input type='checkbox' onChange={(e)=> dispatch(setSelectedRoad(ROAD_CKAD, e.target.checked))} />Москва - ЦКАД</label></div>
                </div>
                <div className='checkboxesSplitter'/>
                <div className='checkboxes'>
                    <div className='checkboxDivTitle'>Параметры транспортного средства</div>
                    <div className='checkboxDiv'><label><input type='number' max="1000" min="0"  onChange={(e)=> setNumber(e,"limitedWeight" )}  />вес брутто, т</label></div>
                    <div className='checkboxDiv'><label><input type='number' max="1000" min="0"  onChange={(e)=> setNumber(e,"weightPerAxle")} />вес на ось, т</label></div>
                    <div className='checkboxDiv'><label><input type='number' max="50" min="0"   onChange={(e)=> setNumber(e,"height" )} />высота, м</label></div>
                    <div className='checkboxDiv'><label><input type='number' max="50" min="0"  onChange={(e)=> setNumber(e,"width" )} />ширина, м</label></div>
                    <div className='checkboxDiv'><label><input type='number' max="300" min="0"  onChange={(e)=> setNumber(e,"length")}  />длина, м</label></div>
                    <div className='checkboxDiv'><label><input type='text' maxLength={1} onChange=
                        {(e)=> {
                                if (['B','C','D','E'].indexOf(e.target.value)>=0) {
                                    dispatch(setParamsTruck("tunnelCategory", e.target.value));
                                }
                                else {
                                    dispatch(setParamsTruck("tunnelCategory", "0"))
                                    e.target.value = '';
                                }
                            }
                        }  />Тунельная кат. (B,C,D,E)</label></div>
                    <div className='checkboxDiv'><label><input type='number' max={100}  onChange={(e)=> setNumber(e,"axleCount" )}  />кол-во осей</label></div>
                </div>
                <div>
                    {/*<textarea>{result}</textarea>*/}
                </div>
            </div>
            <div className='rightSide'>
                <HereMapContainer />

            </div>
        </div>
    )
};
export default MapApp;
