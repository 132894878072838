import React from "react";
import {RouteType} from "../../../redux/mapTypes";
export type PropsMapDisplayResultType = {baseTime :number, trafficTime :number, travelTime :number, distance :number };




export type PropsType ={
    result: PropsMapDisplayResultType,
    route: RouteType | null,
    message: string
}

interface displayRoutePublicTransport{
    type:string,
    type_i:number,
    length:number
}

let MapDisplayResult : React.FC<PropsType> = ({result, route, message}) => {

    let getTypeTransport = (id:string, length:number) => {
        if (!route ) return {type: "Неизвестно", type_i: 0, length};
        let p = route.publicTransportLine.find( x=> x.id === id);
        if (!p)  return {type: "Неизвестно", type_i: 0, length};
        switch (p.type) {
            // trainRegional, trainIntercity, trainHighSpeed, monoRail, aerial, inclined, water, privateService
            case "busExpress" :
            case "busIntercity" :
            case "busTouristic" :
            case "busPublic" : return {type:"Автобус/троллейбус №" + p.lineName, type_i: 2, length};
            case "railMetroRegional" :
            case "railMetro" : return {type:"Метро " + p.lineName, type_i: 3, length};
            case "railLight" : return {type:"Трамвай №" + p.lineName, type_i: 4, length};
            case "railRegional" : return {type:"Ж/д транспорт " + p.lineName, type_i: 5, length};
            case "privateService" : return {type:"Маршрутное такси  №" + p.lineName, type_i: 6, length};
            default : return {type: p.type, type_i: 0, length};
        }
    };

    let textRoute : displayRoutePublicTransport[] = [];
    if (route) {
        route.leg.forEach(x => {
            for (let i = 0; i < x.maneuver.length; i++) {
                let d: displayRoutePublicTransport = x.maneuver[i].line ?
                    getTypeTransport(x.maneuver[i].line, x.maneuver[i].length)
                    : {type: 'Пешком', type_i: 1, length: x.maneuver[i].length};

                if (textRoute.length && textRoute[textRoute.length-1].type_i === d.type_i &&   textRoute[textRoute.length-1].type === d.type ) textRoute[textRoute.length-1].length += d.length;
                else textRoute.push(d);

            }
        })
    }
    return (
        <>
           {(!message || message === '') && <div className='resultInfo'>
                {result && result.baseTime && <div>Время без пробок : { Math.floor(result?.baseTime/60) } мин </div>}
                {result && result.trafficTime && <div>Время с пробками : { Math.floor(result?.trafficTime/60) } мин </div>}
                {result && !result.trafficTime && result.travelTime && <div>Время с пробками : { Math.floor(result?.travelTime/60) } мин </div>}
                {result && result.distance && <div>Дистанция : { Math.floor(result?.distance/1000) } км. </div>}
            </div>
           }

            { textRoute.length > 0 && (!message || message === '') && <div className='legInfo'>
                <ul>
                    { textRoute.map((x,i)=> <li key={'dv' + i}>{x.type + ' ' + (x.length / 1000).toFixed(2) + ' км.'}</li>) }
                </ul>
            </div>
            }

            {(message && message !== '') &&
                <div className='resultInfo'>
                    <div className="textMessage">{message}</div>
                </div>
            }
       </>
    )
};

export default MapDisplayResult;
