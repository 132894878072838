import React from "react";
import {MKAD_FINISH, MKAD_START, wayPointInfo} from "../MapHereCom/CalculateGeometry";
import "./styles.css";

export type PropsType ={
    wayPointsInfo: wayPointInfo[],
}

const getSummaryInfo = (wayPoints: wayPointInfo[]) => {
    let outsideCount = 0;
    let insideState = true;
    let inside = 0;
    let outside :any[][] = [[]];
    let outsideLength = 0;

    wayPoints.forEach(item => {
        console.log('item', item)
        if (item.insideMKAD) {
            insideState = true;
            outside.push([]);
            inside += item.distance;
        } else {
            if (insideState) {
                outsideCount++;
                insideState = false;
            }
            outsideLength += item.distance;

            outside[outside.length - 1].push(item.distance);
        }
    });

    let outsideLengthMinus = 0;
    let outsideSegments = outside
        .filter(item => item.length)
        .map(item => {

            return item.reduce((sum, curr) => {
                return (sum += curr);
            });
        });

    outsideSegments.forEach(item => {
        outsideLengthMinus += Math.max(item - 30000, 0);
    });

    return {
        outsideCount,
        inside,
        outsideLength,
        outsideLengthMinus
    };
}

let MapCalculateResult : React.FC<PropsType> = ({wayPointsInfo}) => {
    const letterArray : string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let div = '';

    const roundTo = (num:number) => Math.round(num * 10) / 10;

    const rows: any[] = [];
    let summary = getSummaryInfo(wayPointsInfo);
    wayPointsInfo.forEach((item, i) => {


        rows.push(
            (<tr key={i}>
                <td className={'classes_' + item.fromReg+ (item.to == MKAD_START ? ', mkad_fs': '')}>{item.from}</td>
                <td> =&gt; </td>
                <td className={'classes_' + item.toReg + (item.to == MKAD_FINISH ? ', mkad_fs': '')}>{item.to}</td>
                <td>{roundTo(item.distance / 1000)} км</td>
                <td>{item.insideMKAD ? "в МКАД" : "за МКАД"}</td>
            </tr>)
        );
    });


    return (
        <div className="abstractWindow">
            <div className="windowContainer">
                <div id="colors">
                    <span className="square classes_bk"  >
                        БК
                    </span>
                    <span className="square classes_sk" >
                        СК
                    </span>
                    <span className="square classes_ttk" >
                        ТТК
                    </span>
                    <span className="square classes_mkad" >
                        МКАД
                    </span>
                    <span className="square classes_ckad" >
                        ЦКАД
                    </span>
                </div>
                <div className="divWithTable">
                        <table id="lengthsPanel">
                            <tbody>
                                {rows}
                                <tr key="inside">
                                     <td colSpan={3}>в МКАД</td>
                                     <td colSpan={2}>{roundTo(summary.inside / 1000)} км</td>
                                </tr>
                                <tr key="outsideLength">
                                    <td colSpan={3}>за МКАД</td>
                                    <td colSpan={2}>{roundTo(summary.outsideLength / 1000)} км</td>
                                </tr>
                                <tr key="outsideCount">
                                     <td colSpan={3}>выездов за МКАД</td>
                                     <td colSpan={2}>{summary.outsideCount} шт</td>
                                </tr>
                                <tr key="outsideLengthMinus">
                                     <td colSpan={3}>за МКАД<br/><span className='mkad_fs'>(с учетом вычета 30 км)</span></td>
                                     <td colSpan={2}>{roundTo(summary.outsideLengthMinus / 1000)} км</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
    )
};

export default MapCalculateResult;
