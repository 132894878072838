import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from "redux-thunk";
import mapReducer from "./mapReducer";


let reducers = combineReducers({
    mapReducer ,
});

type rootReducerType = typeof  reducers;
export type AppStateType = ReturnType<rootReducerType>;
export type AppDispatch = typeof store.dispatch

// @ts-ignore
const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||compose;

let store = createStore(reducers, composeEnhancers(composeWithDevTools(applyMiddleware(thunkMiddleware))));

// @ts-ignore
window.store = store;


export default store;
